import React, { useState, useEffect, useRef } from 'react';

import { SdfButton, SdfCard, SdfAlert, SdfIcon, SfcShellAppBarIcon, SdfSwitch, SdfBusyIndicator } from "@synerg/react-components";
import get from "lodash/get";
import axios from "axios";
import { withNotificationContext } from '../../shared/notifications';
import { SHELL_NAV_ICON } from '../../constants';

const NotificationIcon = ({
  showNotificationMenu,
  setShowNotificationMenu,
  region
}) => {
  const [notificationSettings, setNotificationSettings] = useState({});
  const [notificationToggles, setNotificationToggles] = useState({});
  const [errorMsg, setErrorMsg] = useState('');
  const [hasError, setHasError] = useState(false);
  const [busy, setBusy] = useState(false);
  const menuRef = useRef(null);
  const notificationsIconRef = useRef(null);

  const resetToggles = () => {
    setNotificationToggles({
      certificateExpiration: notificationSettings?.certificateExpiration ?? notificationToggles.certificateExpiration,
      ...(region === 'us' && { sendPickerEmails: notificationSettings?.sendPickerEmails ?? notificationToggles.sendPickerEmails })
    });
  };

  const getSettings = async () => {
    try {
      setBusy(true);
      const { data } = await axios.get(`${process.env.REACT_APP_API_PATH}/user/notification-preferences`);

      setNotificationSettings(data.preferences);
      setNotificationToggles({
        certificateExpiration: data?.preferences?.certificateExpiration ?? false,
        ...(region === 'us' && { sendPickerEmails: data?.preferences?.sendPickerEmails ?? true })
      });

      setBusy(false);

    } catch (e) {
      const errorMessage = get(e, 'response.data.message', 'Something went wrong. Please try again.');
      setHasError(true);
      setErrorMsg(errorMessage);
      setBusy(false);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    const onClickOutside = e => {
      const element = e.target;
      if (notificationsIconRef.current && notificationsIconRef.current.contains(element)) {
        return;
      }

      if (menuRef.current && !menuRef.current.contains(element)) {
        return setShowNotificationMenu(false);
      }
    };

    document.body.addEventListener('click', onClickOutside);

    return () => document.removeEventListener('click', onClickOutside);
  }, [notificationSettings]);


  const changeSettings = async (newSettings = {}) => {
    setBusy(true);
    setHasError(false);
    try {
      await axios.post(`${process.env.REACT_APP_API_PATH}/user/notification-preferences`, newSettings);

      getSettings();
      setShowNotificationMenu(false);
      setBusy(false);

    } catch (e) {
      const errorMessage = get(e, 'response.data.message', 'Something went wrong. Please try again.');
      setHasError(true);
      setErrorMsg(errorMessage);
      setBusy(false);
    }
  };


  return (
    <>
      <SfcShellAppBarIcon
        slot="icons"
        icon="alert-notification"
        ref={notificationsIconRef}
        className={`${SHELL_NAV_ICON} md:mr-4`}
        label="Alerts"
        href="javascript:void(0)"
        onClick={() => {
          setShowNotificationMenu(!showNotificationMenu);
        }}
      />

      <div
        ref={menuRef}
        className={showNotificationMenu ? '' : 'hidden'}
        style={{ position: 'fixed', top: '4rem', right: '8rem' }}
      >
        <SdfCard className='max-w-xs'>
          <div className="pl-inline-md pt-3 pb-4">
            <div className='text-default font-medium font-bold mb-2'>Notification Settings</div>
            <div className='text-default text-sm mb-10'>
              These settings are only for you and will
              <br /> not impact your teammates' settings.
            </div>
            <div>
              <div className='w-64 flex text-default font-medium text-lg font-extrabold mb-4'>
                <div className="w-48">Signed certificate
                  <br />expiration
                </div>
                <div className="pl-inline-lg flex justify-between items-center">
                  <SdfSwitch
                    checked={notificationToggles.certificateExpiration}
                    {...(busy && { disabled: true })}
                    slot="action"
                    className="cursor-pointer"
                    onSdfChange={() => {
                      setNotificationToggles(prev => ({
                        ...prev,
                        certificateExpiration: !notificationToggles.certificateExpiration
                      }));
                    }} >
                    <SdfIcon
                      icon={notificationToggles.certificateExpiration ? "action-confirm" : 'action-close'}
                      style={{
                        position: 'absolute',
                        fontSize: '12px', ...(notificationToggles.certificateExpiration ? { right: '12px' } : { left: '4px' })
                      }}
                    />
                  </SdfSwitch>

                </div>
              </div>
              <div className="text-tertiary text-sm mb-10">
                When your signed certificate is within 60
                <br />days of expiration, you will receive
                <br />multiple notifications at regular intervals
                <br />until it has expired.
              </div>
            </div>

            {region === 'us' && <div>
              <div className='w-64 flex text-default font-medium text-lg font-extrabold mb-4'>
                <div className="w-48">Project Creation or API changes
                </div>
                <div className="pl-inline-lg flex justify-between items-center">
                  <SdfSwitch
                    checked={notificationToggles.sendPickerEmails}
                    {...(busy && { disabled: true })}
                    slot="action"
                    className="cursor-pointer"
                    onSdfChange={() => {
                      setNotificationToggles(prev => ({
                        ...prev,
                        sendPickerEmails: !notificationToggles.sendPickerEmails
                      }));
                    }} >
                    <SdfIcon
                      icon={notificationToggles.sendPickerEmails ? "action-confirm" : 'action-close'}
                      style={{
                        position: 'absolute',
                        fontSize: '12px', ...(notificationToggles.sendPickerEmails ? { right: '12px' } : { left: '4px' })
                      }}
                    />
                  </SdfSwitch>
                </div>
              </div>
              <div className="text-tertiary text-sm mb-10">
                When an API in one of your projects is
                <br />modified, or a new project is created,
                <br />you will be notified by email.
              </div>
            </div>}

            <div className='flex gap-2 ml-24'>
              <SdfButton emphasis="secondary"
                {...(busy && { disabled: true })}
                onClick={() => {
                  resetToggles();
                  setShowNotificationMenu(false);
                }}
              >
                Cancel
              </SdfButton>

              <SdfButton emphasis="primary"
                onClick={() => { changeSettings(notificationToggles) }}
                {...(busy && { disabled: true })}
              >
                <div style={{ height: '1.625rem' }}>
                  <div>Save</div>
                  {busy && <SdfBusyIndicator className='relative' style={{ bottom: '1.25rem' }} size='sm' />}
                </div>
              </SdfButton>
            </div>

            {hasError && <div className="pt-3">
              <SdfAlert type='inline' status='error'>{errorMsg}</SdfAlert>
            </div>}
          </div>
        </SdfCard>
      </div>
    </>
  );
}
export default withNotificationContext(NotificationIcon);
